/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-slides-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 9.78V7.22c0-.096.106-.156.19-.106l2.13 1.279a.125.125 0 010 .214l-2.13 1.28A.125.125 0 017 9.778z"/><path pid="1" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M5 6h6a.5.5 0 01.496.438l.5 4A.5.5 0 0111.5 11h-3v2.016c.863.055 1.5.251 1.5.484 0 .276-.895.5-2 .5s-2-.224-2-.5c0-.233.637-.429 1.5-.484V11h-3a.5.5 0 01-.496-.562l.5-4A.5.5 0 015 6"/>',
    },
});
